import React from "react";
import googlePlayBadge from "./assets/google-play-badge.svg";
import carWash from "./assets/carWash.svg";
import services from "./assets/services.svg";
import serviceStations from "./assets/serviceStations.svg";
import washingStaff from "./assets/washingStaff.svg";
import washingStations from "./assets/washingStations.svg";
import vehiclentMobile from "./assets/vehiclentMobile.svg";
import vehicleServicing from "./assets/vehicleServicing.svg";
import vehicleWashing from "./assets/vehicleWashing.svg";
import greenWashing from "./assets/greenWashing.svg";
import covid19Package from "./assets/covid19Package.svg";

const Home = () => {
  return (
    <>
      {/* Main Header */}
      <div
        className="row center-align header-teal"
        style={{
          background: "linear-gradient(180deg, #68D8D6 0%, #07BEB8 100%)",
          borderRadius: "0px 0px 80px 0px",
          padding: "24px",
          color: "white",
        }}
      >
        <div className="col s12">
          <h1>
            Get The Right Help Anywhere
            <br />
            To Repair Your Vehicle.
          </h1>
          <a href="#service">
            <button
              className="btn btn-large"
              type="submit"
              name="action"
            >
              Check Services
            </button>
          </a>
        </div>
      </div>

      {/*(USPs) Section */}
      <div className="container">
          <h2 className="center section-header-text">
            Service that makes both You and Your Vehicle Shine
          </h2>
          <div className="row">
            <div className="col s12 l6">
              <div className="card">
                <div className="card-content">
                  <h3><u>Rapid Speed Services</u></h3>
                  <p className="left-align">
                    <i class="material-icons">label</i>
                    Our Services are specifically designed and optimized for
                    people who are busy in their work-life and who are
                    experiencing long service duration.
                  </p>
                  <p className="left-align">
                    <i class="material-icons">label</i>
                    Providing Hassle free service is our priority.
                  </p>
                </div>
              </div>
            </div>
            <div className="col s12 l6">
            <div className="card">
              <div className="card-content">
                <h3><u>Transparent</u></h3>
                <p className="left-align">
                  <i class="material-icons">label</i>
                  We believe in fair and upfront pricing.
                </p>
                <p className="left-align">
                  <i class="material-icons">label</i>
                  Get a real time monitoring of your vehicle service.
                </p>
                <p className="left-align">
                  <i class="material-icons">label</i>
                  Diagnose all vehicle repair problems in an honest and
                  professional manner.
                </p>
              </div>
            </div>
          </div>
          </div>
          <div className="row">
            <div className="col s12 l6">
              <div className="card">
                <div className="card-content">
                  <h3><u>Quality</u></h3>
                  <p className="left-align">
                    <i class="material-icons">label</i>
                    Quality service is our top priority.
                  </p>
                  <p className="left-align">
                    <i class="material-icons">label</i>
                    Our expert mechanics understand that the very thought of
                    getting a vehicle repaired can make most people nervous.
                  </p>
                  <p className="left-align">
                    <i class="material-icons">label</i>
                    Genuine spare parts as per OEM (original equipment
                    manufacturer).
                  </p>
                </div>
              </div>
            </div>
            <div className="col s12 l6">
            <div className="card">
              <div className="card-content">
                <h3><u>Affordable</u></h3>
                <p className="left-align">
                  <i class="material-icons">label</i>
                  For those who are not savvy about vehicle repair or are
                  uncertain whether or not the correct repairs are being
                  performed on your vehicle, and they have to pay heavy charges
                  on services. We provide services at affordable prices on which
                  you never hesitate to service your vehicle.
                </p>
              </div>
            </div>
          </div>
          </div>
      </div>

      {/* Services Section */}
      <div
        id="service"
        className="row card-panel"
        style={{ background: "#3DCCC7", color: "white" }}
      >
        <div className="container center">
          <h2 className="center">Choose from our range of services</h2>
          <div className="col s12 m6 l3 section-cards">
            <div className="card-circle">
              <img
                className="card-img"
                src={vehicleServicing}
                alt="Vehicle Servicing"
              />
            </div>
            <h3>Vehicle Servicing
              <p>*Flexible pricing based on Service</p>
            </h3>
            
          </div>
          <div className="col s12 m6 l3 section-cards">
            <div className="card-circle">
              <img
                className="card-img"
                src={vehicleWashing}
                alt="Vehicle Washing"
              />
            </div>
            <h3>Vehicle Washing
              <p>*Starting at Rs.399 only</p>
            </h3>
          </div>
          <div className="col s12 m6 l3 section-cards">
            <div className="card-circle">
              <img
                className="card-img"
                src={greenWashing}
                alt="Green Washing"
              />
            </div>
            <h3>Green Washing</h3>
            <p>*Starting at Rs.549 only</p>
          </div>
          <div className="col s12 m6 l3 section-cards">
            <div className="card-circle">
              <img
                className="card-img"
                src={covid19Package}
                alt="COVID-19 Packages"
              />
            </div>
            <h3>COVID-19 Packages</h3>
            <p>*Starting at Rs.599 only</p>
          </div>
        </div>
      </div>

      {/* Experience Section */}
      <div className="row center">
        <h2 className="center">
          Experience You can Trust
        </h2>
        <div className="container">
          <div className="row">
            <div className="col s12 m4 section-cards">
              <div className="card-circle">
                <img className="card-img" src={services} alt="Services" />
              </div>
              <h3>
                5000+
                <br />
                Services
              </h3>
            </div>
            <div className="col s12 m4 section-cards">
              <div className="card-circle">
                <img className="card-img" src={carWash} alt="Washings" />
              </div>
              <h3>
                10000+
                <br />
                Washings
              </h3>
            </div>
            <div className="col s12 m4 section-cards">
              <div className="card-circle">
                <img
                  className="card-img"
                  src={serviceStations}
                  alt="Service Stations"
                />
              </div>
              <h3>
                50+
                <br />
                Service Stations
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col s12 m4 section-cards">
              <div className="card-circle">
                <img
                  className="card-img"
                  src={washingStaff}
                  alt="Washing Staff"
                />
              </div>
              <h3>
                500+
                <br />
                Washing Staff
              </h3>
            </div>
            <div className="col s12 m4 section-cards">
              <div className="card-circle">
                <img
                  className="card-img"
                  src={washingStations}
                  alt="Washing Stations"
                />
              </div>
              <h3>
                200+
                <br />
                Washing Stations
              </h3>
            </div>
            <div className="col s12 m4 section-cards">
              <div className="card-circle">
                <img
                  className="card-img"
                  src={greenWashing}
                  alt="Green Washing"
                />
              </div>
              <h3>
                8000+
                <br />
                Green Washes
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* Get the Vehiclent App Section */}
      <div
        className="row center"
        style={{ background: "#3DCCC7", padding: "24px", marginBottom: "0" }}
      >
        <div className="container vh-center">
          <div className="col s12 m6">
            <img
              src={vehiclentMobile}
              alt="Vehiclent App"
              className="z-depth-3"
              style={{ borderRadius: "33px" }}
            />
          </div>
          <div className="col s12 m6" style={{ color: "white" }}>
            <h2>Get the Vehiclent App today!</h2>
            <a href="https://play.google.com/store/apps/details?id=com.vehiclent">
              <img
                src={googlePlayBadge}
                style={{ width: "16rem", borderRadius: "10px" }}
                alt="Google Play Badge"
                className="z-depth-3"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
