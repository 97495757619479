import React from "react";
import { Link } from "react-router-dom";

export const NoMatch = () => (
  <>
    <div className="container center" style={{ paddingTop: "10px", marginBottom: "40px" }}>
      <h3>Looks like you've found a page that doesn't exist (maybe not yet!)</h3>
      <h3><Link to="/">&larr; Visit the Vehiclent Garage!</Link></h3>
    </div>
  </>
);
