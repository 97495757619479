import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Home from "./Home";
import About from "./About";
import Faq from "./Faq";
import { NoMatch } from "./NoMatch";
import { Layout } from "./components/Layout"

function App() {
  return (
    <>
      <Router>
        <Navigation />
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/faq" component={Faq} />
            <Route component={NoMatch} />
          </Switch>
          <Footer />
        </Layout>
      </Router>
    </>
  );
}

export default App;
