import React from "react";
// import cardLarge from "./assets/cardLarge.svg";
import amarProfilePic from "./assets/amarProfilePic.jpg";
import ashishProfilePic from "./assets/ashishProfilePic.jpg";

const About = () => {
  return (
    <>
      {/* Header */}
      <div className="row center header-teal header-style">
        <div className="container" style={{ color: "white" }}>
          <h1>
            We're on a mission to build high-quality services so that we may
            Endeavour to be the best in the world
          </h1>
        </div>
      </div>

      <div class="row">
        <div className="container">
          {/* Ashish Chauhan */}
          <div class="col s12 l4 offset-l2">
            <div class="card">
              <div class="card-image">
                <img src={ashishProfilePic} alt="Ashish Chauhan" />
              </div>
              <div class="card-content">
                <span>
                  <h5>Ashish Chauhan</h5>
                  <h6>Founder & CEO</h6>
                </span>
              </div>
              <div class="card-action center">
                <a
                  href="http://twitter.com/ashishchauhan__"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-twitter"
                    style={{
                      fontSize: "30px",
                      color: "#828282",
                    }}
                  ></i>
                </a>
                <a
                  href="http://linkedin.com/in/ashish-chauhan-089598134"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-linkedin"
                    style={{
                      fontSize: "30px",
                      color: "#828282",
                    }}
                  ></i>
                </a>
              </div>
            </div>
          </div>

          {/* Amar Singh */}
          <div class="col s12 l4">
            <div class="card">
              <div class="card-image">
                <img src={amarProfilePic} alt="Amar Singh" />
              </div>
              <div class="card-content">
                <span>
                  <h5>Amar Singh</h5>
                  <h6>COO</h6>
                </span>
              </div>
              <div class="card-action center">
                <a
                  href="https://twitter.com/singhamar584"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-twitter"
                    style={{
                      fontSize: "30px",
                      color: "#828282",
                    }}
                  ></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/amar-singh-8b575367"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="fa fa-linkedin"
                    style={{
                      fontSize: "30px",
                      color: "#828282",
                    }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About us section */}
      <div
        className="row"
        style={{
          background: "#F8F8F8",
          borderRadius: "80px 0px",
          paddingTop: "0.1em",
        }}
      >
        <div className="container">
          <h3>
            About Us
            <p
              style={{
                border: "4px solid #3DCCC7",
                margin: "0px 0px",
                width: "4em",
              }}
            ></p>
          </h3>
          <p className="col s12">
            Welcome to VEHICLENT, we are the number one source of all
            vehicle-related services. We're giving you the authority to choose
            our services, with a focus on “Reliable, Transparent, Affordable and
            Rapid speed, ” services.
            <p>
              Founded in March 2019 by Mr Ashish Chauhan, VEHICLENT has come a
              long way from its beginnings in the small area of North-West
              Delhi. When Mr Ashish Chauhan first started, his passion for
              providing affordable vehicle services and cleaning drove them to
              action to do tons of research. So that VEHICLENT can offer you the
              most affordable and transparent services. We now serve customers
              all over Delhi- NCR and are thrilled that we're able to turn our
              passion in our Application and website.
            </p>
            <p>
              We hope you enjoy our products as much as we enjoy offering them
              to you. If you have any questions or comments, please don't
              hesitate to contact us.
            </p>
            <p>Sincerely,<br />
            Ashish Chauhan</p>
          </p>
          {/* <div className="col s12 m6">
            <img src={cardLarge} alt="Gallery" width="100%" style={{}} />
          </div> */}
        </div>
      </div>
    </>
  );
};
export default About;
