import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import M from "materialize-css/dist/js/materialize.min.js";

const modalStyle = {
  background: "#05668d",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
};

const modalContentHeader = {
  position: "absolute",
  top: "0",
  right: "0px",
  width: "100%",
  background: "#07BEB8",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px 10px 0px 0px",
  color: "white",
};

const modalContent = {
  filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))",
  background: "#F8F8F8",
  borderRadius: "4px",
  borderBottom: "none",
  paddingLeft: "1em",
  minWidth: "60%",
  maxWidth: "50%",
};

const Navigation = () => {
  const searchModal = useRef(null);
  useEffect(() => {
    M.Modal.init(searchModal.current);
  }, []);

  useEffect(() => {
    var elems = document.querySelectorAll(".sidenav");
    M.Sidenav.init(elems, {});
  }, []);

  return (
    <>
      <div class="navbar-fixed">
        {/* Navbar */}
        <nav class="nav-extended white">
          <div className="container">
            <div className="nav-wrapper">
              <Link to="/" class="brand-logo">
                <img
                  src={logo}
                  width="120"
                  height="40"
                  style={{ margin: "10px 50px" }}
                  alt="Vehiclent Services Pvt. Ltd."
                />
              </Link>
              {/* Desktop Nav */}
              <ul id="nav-mobile" class="right hide-on-med-and-down">
                <li>
                  <Link to="/" style={{ color: "black" }}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" style={{ color: "black" }}>
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    data-target="contactModal"
                    className=" modal-trigger"
                    style={{ color: "black" }}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/faq" style={{ color: "black" }}>
                    FAQ!
                  </Link>
                </li>
              </ul>
            </div>
            {/* Mbile Nav */}
            <div className="nav-content">
              <ul className="tabs show-on-medium-and-down hide-on-med-and-up">
                <div className="row">
                  <li class="tab col offset-s1">
                    <Link to="/" style={{ color: "black" }}>
                      Home
                    </Link>
                  </li>
                  <li class="tab col">
                    <Link to="/about" style={{ color: "black" }}>
                      About
                    </Link>
                  </li>
                  <li class="tab col">
                    <Link
                      data-target="contactModal"
                      className=" modal-trigger"
                      style={{ color: "black" }}
                    >
                      Contact
                    </Link>
                  </li>
                  <li class="tab col">
                    <Link to="/faq" style={{ color: "black" }}>
                      FAQ!
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </nav>

        {/* Contact Modal */}
        <div
          id="contactModal"
          className="container modal"
          ref={searchModal}
          style={modalStyle}
        >
          <div className="row modal-content center">
            <div style={modalContentHeader}>
              <h2
                style={{
                  margin: "1.9466666667rem 0 1.168rem",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                }}
              >
                Contact Us
              </h2>
            </div>
          </div>

          <div className="modal-content" style={{ marginTop: "8vh" }}>
            <input
              style={modalContent}
              type="text"
              placeholder="Your Name..."
            />
            <input
              style={modalContent}
              type="text"
              placeholder="Your Email..."
            />
            <input
              style={modalContent}
              type="text"
              placeholder="Your Phone No."
            />
            <textarea
              id="textarea1"
              class="materialize-textarea"
              placeholder="How can we help..."
              style={{
                filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))",
                background: "#F8F8F8",
                borderRadius: "4px",
                borderBottom: "none",
                paddingLeft: "1em",
                minWidth: "80%",
                maxWidth: "50%",
                minHeight: "200px"
              }}
            ></textarea>
            <button class="btn btn-large" type="submit" name="action">
              Send Message<i class="material-icons right">send</i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
