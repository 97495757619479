import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  h1 {
    font-size: 3.5rem;
    font-weight: bold;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px;
    text-decoration: underline;
  }

  h3 {
    font-size: 2rem;
    font-weight: bold;
  }

  .material-icons {
    color: #3dccc7;
    font-size: 1.125rem;
    margin-right: 0.25em;
  }

  p {
    font-size: 1.125rem;
  }

  .header-style {
    background: linear-gradient(180deg, #68d8d6 0%, #07beb8 100%);
    border-radius: 0px 0px 80px 0px;
    padding: 24px;
  }

  button {
    font-family: Montserrat, sans-serif;
    background: #076c95;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.50);
    border-radius: 10px;

    &:hover {
      background: #05638a;
      cursor: pointer;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
  }

  .vh-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 700px) {
    margin-top: 12vw;

    h1 {
      font-size: 12vw;
      font-weight: bold;
      font-weight: bold;
    }

    h2 {
      font-size: 10vw;
      font-weight: bold;
      text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px;
      text-decoration: underline;
    }

    h3 {
      font-size: 7vw;
      font-weight: bold;
    }

    p {
      font-size: 5vw;
    }

    .material-icons {
      font-size: 5vw;
      margin-right: 0.20em;
    }
    
    .card .card-content p {
      text-indent: -0.5em;
    }
  }
`;

export const Layout = (props) => <Styles>{props.children}</Styles>;
