import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import googlePlay from "../assets/google-play-badge.svg";
import M from "materialize-css/dist/js/materialize.min.js";

const modalContentHeader = {
  position: "absolute",
  top: "0",
  right: "0px",
  width: "100%",
  background: "#07BEB8",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  color: "#FFFFFF",
};

const modalStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
};

const modalContent = {
  filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))",
  background: "#F8F8F8",
  borderRadius: "4px",
  borderBottom: "none",
  paddingLeft: "1em",
};

const Footer = () => {
  const privacy = useRef(null);
  const term = useRef(null);
  const searchModal = useRef(null);

  useEffect(() => {
    M.Modal.init(searchModal.current);
  }, []);

  useEffect(() => {
    M.Modal.init(privacy.current);
  }, []);
  useEffect(() => {
    M.Modal.init(term.current);
  }, []);

  return (
    <>
      <footer class="page-footer" style={{ background: "#05668D" }}>
        <div class="container center">
          <div class="row">
            {/* Vehiclent Links */}
            <div class="col s12 m3 offset-m1">
              <h5 class="white-text">Vehiclent</h5>
              <ul>
                <li>
                  <Link to="/" class="grey-text text-lighten-3">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" class="grey-text text-lighten-3">
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    data-target="contactModal"
                    className=" modal-trigger grey-text text-lighten-3"
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="/faq" class="grey-text text-lighten-3">
                    FAQ!
                  </Link>
                </li>
                <li>
                  <Link
                    data-target="privacyModal"
                    className="modal-trigger grey-text text-lighten-3"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    data-target="termModal"
                    className="modal-trigger grey-text text-lighten-3"
                  >
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
            </div>

            {/* Resources Links */}
            {/* <div class="col s12 l3">
              <h5 class="white-text">Resources</h5>
              <ul>
                <li>
                  <a class="grey-text" href="#!">
                    Blog
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="#!">
                    Customer Stories
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="#!">
                    Partner Stories
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="#!">
                    Brand
                  </a>
                </li>
              </ul>
            </div> */}

            {/* Socials */}
            <div class="col s12 m3">
              <h5 class="white-text">Socials</h5>
              <ul>
                <li>
                  <a
                    class="grey-text text-lighten-3"
                    href="https://twitter.com/vehiclent"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    class="grey-text text-lighten-3"
                    href="https://www.linkedin.com/company/vechiclent/"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    class="grey-text text-lighten-3"
                    href="https://instagram.com/vehiclent"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    class="grey-text text-lighten-3"
                    href="https://facebook.com/vehiclent"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </div>

            {/* Get the App */}
            <div class="col s12 m3">
              <h5 class="white-text">Download the App</h5>
              <a href="https://play.google.com/store/apps/details?id=com.vehiclent">
                <img
                  src={googlePlay}
                  className="z-depth-3"
                  style={{ width: "16em" }}
                  alt="Google Play Badge"
                />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div
          class="footer-copyright grey-text text-lighten-1"
          style={{ background: "#05668d" }}
        >
          <div class="container center">
            <hr />
            &copy; 2020 Vehiclent Services Pvt. Ltd.<br />
            All rights reserved.
          </div>
        </div>
      </footer>

      {/* Contact Modal */}
      <div
        id="contactModal"
        className="container modal center"
        ref={searchModal}
        style={modalStyle}
      >
        <div className="modal-content">
          <div style={modalContentHeader}>
            <h3
              style={{
                margin: "1.9466666667rem 0 1.168rem",
                fontWeight: "bold",
                // fontSize: "50px",
              }}
            >
              Contact Us
              <button className="modal-close btn-flat">
                <i className="material-icons">close</i>
              </button>
            </h3>
          </div>
        </div>

        <div className="modal-content white" style={{ marginTop: "3em" }}>
          <div>
            <input
              style={modalContent}
              type="text"
              placeholder="Your Name..."
            />
            <input
              style={modalContent}
              type="text"
              placeholder="Your Email..."
            />
            <input
              style={modalContent}
              type="text"
              placeholder="Your Phone No."
            />
            <textarea
              id="textarea1"
              class="materialize-textarea"
              placeholder="How can we help..."
              style={modalContent}
            ></textarea>
            <button
              class="btn-large waves-effect waves-light"
              type="submit"
              name="action"
            >
              Send Message<i class="material-icons">send</i>
            </button>
          </div>
        </div>
      </div>

      {/* Privacy Modal */}
      <div
        id="privacyModal"
        className="modal container"
        ref={privacy}
        style={{ borderRadius: "10px 10px 0px 0px" }}
      >
        <div className="row">
          <div className="col s12" style={modalContentHeader}>
            <h2
              style={{
                marginTop: "28px",
              }}
            >
              Privacy policy
            </h2>
            <p>Effective date: 2020-04-15 </p>
          </div>
        </div>
        <div className="row" style={{ marginTop: "178px" }}>
          <div className="col s10 offset-s1 privacyStyle">
            <h4>Introduction</h4>
            <p>
              Welcome to VEHICLENT SERVICES PVT. LTD. VEHICLENT SERVICES PVT.
              LTD. (“us”, “we”, or “our”) operates https://www.vehiclent.com
              (hereinafter referred to as “Service”). Our Privacy Policy governs
              your visit to https://www.vehiclent.com, and explains how we
              collect, safeguard and disclose information that results from your
              use of our Service. We use your data to provide and improve
              Service. By using Service, you agree to the collection and use of
              information in accordance with this policy. Unless otherwise
              defined in this Privacy Policy, the terms used in this Privacy
              Policy have the same meanings as in our Terms and Conditions. Our
              Terms and Conditions ( “Terms”) govern all use of our Service and
              together with the Privacy Policy constitutes your agreement with
              us (“agreement”).
            </p>

            <h4>Definitions</h4>
            <p>
              SERVICE means the https://www.vehiclent.com website operated by
              VEHICLENT SERVICES PVT. LTD.. PERSONAL DATA means data about a
              living individual who can be identified from those data (or from
              those and other information either in our possession or likely to
              come into our possession). USAGE DATA is data collected
              automatically either generated by the use of Service or from
              Service infrastructure itself (for example, the duration of a page
              visit). COOKIES are small files stored on your device (computer or
              mobile device). DATA CONTROLLER means a natural or legal person
              who (either alone or jointly or in common with other persons)
              determines the purposes for which and the manner in which any
              personal data are, or are to be, processed. For the purpose of
              this Privacy Policy, we are a Data Controller of your data. DATA
              PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
              person who processes the data on behalf of the Data Controller. We
              may use the services of various Service Providers in order to
              process your data more effectively. DATA SUBJECT is any living
              individual who is the subject of Personal Data. THE USER is the
              individual using our Service. The User corresponds to the Data
              Subject, who is the subject of Personal Data.
            </p>

            <h4>Information Collection and Use</h4>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>

            <h4>Types of Data Collected</h4>
            <p>
              Personal Data While using our Service, we may ask you to provide
              us with certain personally identifiable information that can be
              used to contact or identify you (“Personal Data”). Personally
              identifiable information may include, but is not limited to: 0.1.
              Email address 0.2. First name and last name 0.3. Phone number 0.4.
              Address, Country, State, Province, ZIP/Postal code, City 0.5.
              Cookies and Usage Data We may use your Personal Data to contact
              you with newsletters, marketing or promotional materials and other
              information that may be of interest to you. You may opt out of
              receiving any, or all, of these communications from us by
              following the unsubscribe link. We may also collect information
              that your browser sends whenever you visit our Service or when you
              access Service by or through any device (“Usage Data”). This Usage
              Data may include information such as your computer’s Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that you visit, the time and date of your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data. When you access Service with a device,
              this Usage Data may include information such as the type of device
              you use, your device unique ID, the IP address of your device,
              your device operating system, the type of Internet browser you
              use, unique device identifiers and other diagnostic data. Location
              Data We may use and store information about your location if you
              give us permission to do so (“Location Data”). We use this data to
              provide features of our Service, to improve and customize our
              Service. You can enable or disable location services when you use
              our Service at any time by way of your device settings. Tracking
              Cookies Data We use cookies and similar tracking technologies to
              track the activity on our Service and we hold certain information.
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service. You can
              instruct your browser to refuse all cookies or to indicate when a
              cookie is being sent. However, if you do not accept cookies, you
              may not be able to use some portions of our Service. Examples of
              Cookies we use: 0.1. Session Cookies: We use Session Cookies to
              operate our Service. 0.2. Preference Cookies: We use Preference
              Cookies to remember your preferences and various settings. 0.3.
              Security Cookies: We use Security Cookies for security purposes.
              0.4. Advertising Cookies: Advertising Cookies are used to serve
              you with advertisements that may be relevant to you and your
              interests. Other Data While using our Service, we may also collect
              the following information: sex, age, date of birth, place of
              birth, passport details, citizenship, registration at place of
              residence and actual address, telephone number (work, mobile),
              details of documents on education, qualification, professional
              training, employment agreements, non-disclosure agreements,
              information on bonuses and compensation, information on marital
              status, family members, social security (or other taxpayer
              identification) number, office location and other data.
            </p>

            <h4>Use of Data</h4>
            <p>
              VEHICLENT SERVICES PVT. LTD. uses the collected data for various
              purposes: 0.1. to provide and maintain our Service; 0.2. to notify
              you about changes to our Service; 0.3. to allow you to participate
              in interactive features of our Service when you choose to do so;
              0.4. to provide customer support; 0.5. to gather analysis or
              valuable information so that we can improve our Service; 0.6. to
              monitor the usage of our Service; 0.7. to detect, prevent and
              address technical issues; 0.8. to fulfil any other purpose for
              which you provide it; 0.9. to carry out our obligations and
              enforce our rights arising from any contracts entered into between
              you and us, including for billing and collection; 0.10. to provide
              you with notices about your account and/or subscription, including
              expiration and renewal notices, email-instructions, etc.; 0.11. to
              provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information; 0.12. in
              any other way we may describe when you provide the information;
              0.13. for any other purpose with your consent.
            </p>

            <h4>Retention of Data</h4>
            <p>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies. We will also retain
              Usage Data for internal analysis purposes. Usage Data is generally
              retained for a shorter period, except when this data is used to
              strengthen the security or to improve the functionality of our
              Service, or we are legally obligated to retain this data for
              longer time periods.
            </p>

            <h4>Transfer of Data</h4>
            <p>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
              If you are located outside India and choose to provide information
              to us, please note that we transfer the data, including Personal
              Data, to India and process it there. Your consent to this Privacy
              Policy followed by your submission of such information represents
              your agreement to that transfer. VEHICLENT SERVICES PVT. LTD. will
              take all the steps reasonably necessary to ensure that your data
              is treated securely and in accordance with this Privacy Policy and
              no transfer of your Personal Data will take place to an
              organisation or a country unless there are adequate controls in
              place including the security of your data and other personal
              information.
            </p>

            <h4>Disclosure of Data</h4>
            <p>
              We may disclose personal information that we collect, or you
              provide: 0.1. Disclosure for Law Enforcement. Under certain
              circumstances, we may be required to disclose your Personal Data
              if required to do so by law or in response to valid requests by
              public authorities. 0.2. Business Transaction. If we or our
              subsidiaries are involved in a merger, acquisition or asset sale,
              your Personal Data may be transferred. 0.3. Other cases. We may
              disclose your information also: 0.3.1. to our subsidiaries and
              affiliates; 0.3.2. to contractors, service providers, and other
              third parties we use to support our business; 0.3.3. to fulfill
              the purpose for which you provide it; 0.3.4. for the purpose of
              including your company’s logo on our website; 0.3.5. for any other
              purpose disclosed by us when you provide the information; 0.3.6.
              with your consent in any other cases; 0.3.7. if we believe
              disclosure is necessary or appropriate to protect the rights,
              property, or safety of the Company, our customers, or others.
            </p>

            <h4>Security of Data</h4>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>

            <h4>Service Providers</h4>
            <p>
              We may employ third party companies and individuals to facilitate
              our Service (“Service Providers”), provide Service on our behalf,
              perform Service-related services or assist us in analysing how our
              Service is used. These third parties have access to your Personal
              Data only to perform these tasks on our behalf and are obligated
              not to disclose or use it for any other purpose.
            </p>

            <h4>Analytics</h4>
            <p>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>

            <h4>CI/CD tools</h4>
            <p>
              We may use third-party Service Providers to automate the
              development process of our Service.
            </p>

            <h4>Behavioral Remarketing</h4>
            <p>
              We may use remarketing services to advertise on third party
              websites to you after you visited our Service. We and our
              third-party vendors use cookies to inform, optimise and serve ads
              based on your past visits to our Service.
            </p>

            <h4>Payments</h4>
            <p>
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing
              (e.g. payment processors). We will not store or collect your
              payment card details. That information is provided directly to our
              third-party payment processors whose use of your personal
              information is governed by their Privacy Policy. These payment
              processors adhere to the standards set by PCI-DSS as managed by
              the PCI Security Standards Council, which is a joint effort of
              brands like Visa, Mastercard, American Express and Discover.
              PCI-DSS requirements help ensure the secure handling of payment
              information.
            </p>

            <h4>Links to Other Sites</h4>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party’s site. We strongly advise you to review the
              Privacy Policy of every site you visit. We have no control over
              and assume no responsibility for the content, privacy policies or
              practices of any third party sites or services.
            </p>

            <h4>Children’s Privacy</h4>
            <p>
              Our Services are not intended for use by children under the age of
              18 (“Child” or “Children”). We do not knowingly collect personally
              identifiable information from Children under 18. If you become
              aware that a Child has provided us with Personal Data, please
              contact us. If we become aware that we have collected Personal
              Data from Children without verification of parental consent, we
              take steps to remove that information from our servers.
            </p>

            <h4>Changes to This Privacy Policy</h4>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>

            <h4>Contact Us</h4>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us by email: Click {` `}
              <Link data-target="contactModal" className=" modal-trigger">
                here to contact us.
              </Link>
            </p>
          </div>
        </div>
      </div>

      {/* Terms and Conditions Modal */}
      <div
        id="termModal"
        className="modal container"
        ref={term}
        style={{ borderRadius: "10px 10px 0px 0px" }}
      >
        <div className="row">
          <div className="col s12 m5 offset-m1" style={modalContentHeader}>
            <h2
              style={{
                marginTop: "28px",
              }}
            >
              Terms and Conditions
            </h2>
            <p>Last Updated: 2020-04-15</p>
          </div>
        </div>
        <div className="row" style={{ marginTop: "178px" }}>
          <div className="col s10 offset-s1 termStyle">
            <h4>Introduction</h4>
            <p>
              Welcome to VEHICLENT SERVICES PVT. LTD.(“Company”, “we”, “our”,
              “us”)! These Terms of Service (“Terms”, “Terms of Service”) govern
              your use of our website located at https://www.vehiclent.com
              (together or individually “Service”) operated by VEHICLENT
              SERVICES PVT. LTD.. Our Privacy Policy also governs your use of
              our Service and explains how we collect, safeguard and disclose
              information that results from your use of our web pages. Your
              agreement with us includes these Terms and our Privacy Policy
              (“Agreements”). You acknowledge that you have read and understood
              Agreements, and agree to be bound of them. If you do not agree
              with (or cannot comply with) Agreements, then you may not use the
              Service, but please let us know by contacting us so we can try to
              find a solution. These Terms apply to all visitors, users and
              others who wish to access or use Service.
            </p>

            <h4>Communications</h4>
            <p>
              By using our Service, you agree to subscribe to newsletters,
              marketing or promotional materials and other information we may
              send. However, you may opt out of receiving any, or all, of these
              communications from us by following the unsubscribe link or by
              contacting us.
            </p>

            <h4>Purchases</h4>
            <p>
              If you wish to purchase any product or service made available
              through Service (“Purchase”), you may be asked to supply certain
              information relevant to your Purchase including but not limited
              to, your credit or debit card number, the expiration date of your
              card, your billing address, and your shipping information. You
              represent and warrant that: (i) you have the legal right to use
              any card(s) or other payment method(s) in connection with any
              Purchase; and that (ii) the information you supply to us is true,
              correct and complete. We may employ the use of third party
              services for the purpose of facilitating payment and the
              completion of Purchases. By submitting your information, you grant
              us the right to provide the information to these third parties
              subject to our Privacy Policy. We reserve the right to refuse or
              cancel your order at any time for reasons including but not
              limited to: product or service availability, errors in the
              description or price of the product or service, error in your
              order or other reasons. We reserve the right to refuse or cancel
              your order if fraud or an unauthorized or illegal transaction is
              suspected.
            </p>

            <h4>Contests, Sweepstakes and Promotions</h4>
            <p>
              Any contests, sweepstakes or other promotions (collectively,
              “Promotions”) made available through Service may be governed by
              rules that are separate from these Terms of Service. If you
              participate in any Promotions, please review the applicable rules
              as well as our Privacy Policy. If the rules for a Promotion
              conflict with these Terms of Service, Promotion rules will apply.
            </p>

            <h4>Subscriptions</h4>
            <p>
              Some parts of Service are billed on a subscription basis
              ("Subscription(s)"). You will be billed in advance on a recurring
              and periodic basis ("Billing Cycle"). Billing cycles will be set
              depending on the type of subscription plan you select when
              purchasing a Subscription. At the end of each Billing Cycle, your
              Subscription will automatically renew under the exact same
              conditions unless you cancel it or VEHICLENT SERVICES PVT. LTD.
              cancels it. You may cancel your Subscription renewal either
              through your online account management page or by contacting our
              customer support team. A valid payment method is required to
              process the payment for your subscription. You shall provide
              VEHICLENT SERVICES PVT. LTD. with accurate and complete billing
              information that may include but not limited to full name,
              address, state, postal or zip code, telephone number, and a valid
              payment method information. By submitting such payment
              information, you automatically authorize VEHICLENT SERVICES PVT.
              LTD. to charge all Subscription fees incurred through your account
              to any such payment instruments. Should automatic billing fail to
              occur for any reason, VEHICLENT SERVICES PVT. LTD. reserves the
              right to terminate your access to the Service with immediate
              effect.
            </p>

            <h4>Free Trial</h4>
            <p>
              VEHICLENT SERVICES PVT. LTD. may, at its sole discretion, offer a
              Subscription with a free trial for a limited period of time ("Free
              Trial"). You may be required to enter your billing information in
              order to sign up for Free Trial. If you do enter your billing
              information when signing up for Free Trial, you will not be
              charged by VEHICLENT SERVICES PVT. LTD. until Free Trial has
              expired. On the last day of Free Trial period, unless you
              cancelled your Subscription, you will be automatically charged the
              applicable Subscription fees for the type of Subscription you have
              selected. At any time and without notice, VEHICLENT SERVICES PVT.
              LTD. reserves the right to (i) modify Terms of Service of Free
              Trial offer, or (ii) cancel such Free Trial offer.
            </p>

            <h4>Fee Changes</h4>
            <p>
              VEHICLENT SERVICES PVT. LTD., in its sole discretion and at any
              time, may modify Subscription fees for the Subscriptions. Any
              Subscription fee change will become effective at the end of the
              then-current Billing Cycle. VEHICLENT SERVICES PVT. LTD. will
              provide you with a reasonable prior notice of any change in
              Subscription fees to give you an opportunity to terminate your
              Subscription before such change becomes effective. Your continued
              use of Service after Subscription fee change comes into effect
              constitutes your agreement to pay the modified Subscription fee
              amount.
            </p>

            <h4>Content</h4>
            <p>
              Our Service allows you to post, link, store, share and otherwise
              make available certain information, text, graphics, videos, or
              other material (“Content”). You are responsible for Content that
              you post on or through Service, including its legality,
              reliability, and appropriateness. By posting Content on or through
              Service, You represent and warrant that: (i) Content is yours (you
              own it) and/or you have the right to use it and the right to grant
              us the rights and license as provided in these Terms, and (ii)
              that the posting of your Content on or through Service does not
              violate the privacy rights, publicity rights, copyrights, contract
              rights or any other rights of any person or entity. We reserve the
              right to terminate the account of anyone found to be infringing on
              a copyright. You retain any and all of your rights to any Content
              you submit, post or display on or through Service and you are
              responsible for protecting those rights. We take no responsibility
              and assume no liability for Content you or any third party posts
              on or through Service. However, by posting Content using Service
              you grant us the right and license to use, modify, publicly
              perform, publicly display, reproduce, and distribute such Content
              on and through Service. You agree that this license includes the
              right for us to make your Content available to other users of
              Service, who may also use your Content subject to these Terms.
              VEHICLENT SERVICES PVT. LTD. has the right but not the obligation
              to monitor and edit all Content provided by users. In addition,
              Content found on or through this Service are the property of
              VEHICLENT SERVICES PVT. LTD. or used with permission. You may not
              distribute, modify, transmit, reuse, download, repost, copy, or
              use said Content, whether in whole or in part, for commercial
              purposes or for personal gain, without express advance written
              permission from us.
            </p>

            <h4>Prohibited Uses</h4>
            <p>
              You may use Service only for lawful purposes and in accordance
              with Terms. You agree not to use Service: 0.1. In any way that
              violates any applicable national or international law or
              regulation. 0.2. For the purpose of exploiting, harming, or
              attempting to exploit or harm minors in any way by exposing them
              to inappropriate content or otherwise. 0.3. To transmit, or
              procure the sending of, any advertising or promotional material,
              including any “junk mail”, “chain letter,” “spam,” or any other
              similar solicitation. 0.4. To impersonate or attempt to
              impersonate Company, a Company employee, another user, or any
              other person or entity. 0.5. In any way that infringes upon the
              rights of others, or in any way is illegal, threatening,
              fraudulent, or harmful, or in connection with any unlawful,
              illegal, fraudulent, or harmful purpose or activity. 0.6. To
              engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of Service, or which, as determined by us, may
              harm or offend Company or users of Service or expose them to
              liability. Additionally, you agree not to: 0.1. Use Service in any
              manner that could disable, overburden, damage, or impair Service
              or interfere with any other party’s use of Service, including
              their ability to engage in real time activities through Service.
              0.2. Use any robot, spider, or other automatic device, process, or
              means to access Service for any purpose, including monitoring or
              copying any of the material on Service. 0.3. Use any manual
              process to monitor or copy any of the material on Service or for
              any other unauthorized purpose without our prior written consent.
              0.4. Use any device, software, or routine that interferes with the
              proper working of Service. 0.5. Introduce any viruses, trojan
              horses, worms, logic bombs, or other material which is malicious
              or technologically harmful. 0.6. Attempt to gain unauthorized
              access to, interfere with, damage, or disrupt any parts of
              Service, the server on which Service is stored, or any server,
              computer, or database connected to Service. 0.7. Attack Service
              via a denial-of-service attack or a distributed denial-of-service
              attack. 0.8. Take any action that may damage or falsify Company
              rating. 0.9. Otherwise attempt to interfere with the proper
              working of Service.
            </p>

            <h4>Analytics</h4>
            <p>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>

            <h4>No Use By Minors</h4>
            <p>
              Service is intended only for access and use by individuals at
              least eighteen (18) years old. By accessing or using Service, you
              warrant and represent that you are at least eighteen (18) years of
              age and with the full authority, right, and capacity to enter into
              this agreement and abide by all of the terms and conditions of
              Terms. If you are not at least eighteen (18) years old, you are
              prohibited from both the access and usage of Service.
            </p>

            <h4>Accounts</h4>
            <p>
              When you create an account with us, you guarantee that you are
              above the age of 18, and that the information you provide us is
              accurate, complete, and current at all times. Inaccurate,
              incomplete, or obsolete information may result in the immediate
              termination of your account on Service. You are responsible for
              maintaining the confidentiality of your account and password,
              including but not limited to the restriction of access to your
              computer and/or account. You agree to accept responsibility for
              any and all activities or actions that occur under your account
              and/or password, whether your password is with our Service or a
              third-party service. You must notify us immediately upon becoming
              aware of any breach of security or unauthorized use of your
              account. You may not use as a username the name of another person
              or entity or that is not lawfully available for use, a name or
              trademark that is subject to any rights of another person or
              entity other than you, without appropriate authorization. You may
              not use as a username any name that is offensive, vulgar or
              obscene. We reserve the right to refuse service, terminate
              accounts, remove or edit content, or cancel orders in our sole
              discretion.
            </p>

            <h4>Intellectual Property</h4>
            <p>
              Service and its original content (excluding Content provided by
              users), features and functionality are and will remain the
              exclusive property of VEHICLENT SERVICES PVT. LTD. and its
              licensors. Service is protected by copyright, trademark, and other
              laws of and foreign countries. Our trademarks may not be used in
              connection with any product or service without the prior written
              consent of VEHICLENT SERVICES PVT. LTD..
            </p>

            <h4>Copyright Policy</h4>
            <p>
              We respect the intellectual property rights of others. It is our
              policy to respond to any claim that Content posted on Service
              infringes on the copyright or other intellectual property rights
              (“Infringement”) of any person or entity. If you are a copyright
              owner, or authorized on behalf of one, and you believe that the
              copyrighted work has been copied in a way that constitutes
              copyright infringement, please submit your claim by contacting us,
              with the subject line: “Copyright Infringement” and include in
              your claim a detailed description of the alleged Infringement as
              detailed below, under “DMCA Notice and Procedure for Copyright
              Infringement Claims” You may be held accountable for damages
              (including costs and attorneys’ fees) for misrepresentation or
              bad-faith claims on the infringement of any Content found on
              and/or through Service on your copyright.
            </p>

            <h4>DMCA Notice and Procedure for Copyright Infringement Claims</h4>
            <p>
              You may submit a notification pursuant to the Digital Millennium
              Copyright Act (DMCA) by providing our Copyright Agent with the
              following information in writing (see 17 U.S.C 512(c)(3) for
              further detail): 0.1. an electronic or physical signature of the
              person authorized to act on behalf of the owner of the copyright’s
              interest; 0.2. a description of the copyrighted work that you
              claim has been infringed, including the URL (i.e., web page
              address) of the location where the copyrighted work exists or a
              copy of the copyrighted work; 0.3. identification of the URL or
              other specific location on Service where the material that you
              claim is infringing is located; 0.4. your address, telephone
              number, and email address; 0.5. a statement by you that you have a
              good faith belief that the disputed use is not authorized by the
              copyright owner, its agent, or the law; 0.6. a statement by you,
              made under penalty of perjury, that the above information in your
              notice is accurate and that you are the copyright owner or
              authorized to act on the copyright owner’s behalf. You can contact
              our Copyright Agent.
            </p>

            <h4>Error Reporting and Feedback</h4>
            <p>
              You may provide us either directly by contacting us or via third
              party sites and tools with information and feedback concerning
              errors, suggestions for improvements, ideas, problems, complaints,
              and other matters related to our Service (“Feedback”). You
              acknowledge and agree that: (i) you shall not retain, acquire or
              assert any intellectual property right or other right, title or
              interest in or to the Feedback; (ii) Company may have development
              ideas similar to the Feedback; (iii) Feedback does not contain
              confidential information or proprietary information from you or
              any third party; and (iv) Company is not under any obligation of
              confidentiality with respect to the Feedback. In the event the
              transfer of the ownership to the Feedback is not possible due to
              applicable mandatory laws, you grant Company and its affiliates an
              exclusive, transferable, irrevocable, free-of-charge,
              sub-licensable, unlimited and perpetual right to use (including
              copy, modify, create derivative works, publish, distribute and
              commercialize) Feedback in any manner and for any purpose.
            </p>

            <h4>Links To Other Web Sites</h4>
            <p>
              Our Service may contain links to third party web sites or services
              that are not owned or controlled by VEHICLENT SERVICES PVT. LTD..
              VEHICLENT SERVICES PVT. LTD. has no control over, and assumes no
              responsibility for the content, privacy policies, or practices of
              any third party web sites or services. We do not warrant the
              offerings of any of these entities/individuals or their websites.
              YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
              LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
              ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE
              ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY
              SUCH THIRD PARTY WEB SITES OR SERVICES. WE STRONGLY ADVISE YOU TO
              READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY
              WEB SITES OR SERVICES THAT YOU VISIT
            </p>

            <h4>Disclaimer Of Warranty</h4>
            <p>
              THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
              AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES
              OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
              SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
              THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES,
              THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT
              YOUR SOLE RISK. NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH
              COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
              COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
              AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING,
              NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR
              WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR
              ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE,
              ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT
              THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE
              MEET YOUR NEEDS OR EXPECTATIONS. COMPANY HEREBY DISCLAIMS ALL
              WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
              OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
              MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR
              PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT
              BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>

            <h4>Limitation Of Liability</h4>
            <p>
              EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
              DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
              PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
              ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND
              EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL,
              IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED),
              WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS
              ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT,
              INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR
              PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY
              YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
              REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE
              IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO
              THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO
              CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES.
              SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE,
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR
              EXCLUSION MAY NOT APPLY TO YOU.
            </p>

            <h4>Termination</h4>
            <p>
              We may terminate or suspend your account and bar access to Service
              immediately, without prior notice or liability, under our sole
              discretion, for any reason whatsoever and without limitation,
              including but not limited to a breach of Terms. If you wish to
              terminate your account, you may simply discontinue using Service.
              All provisions of Terms which by their nature should survive
              termination shall survive termination, including, without
              limitation, ownership provisions, warranty disclaimers, indemnity
              and limitations of liability.
            </p>

            <h4>Governing Law</h4>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of INDIA, which governing law applies to agreement without
              regard to its conflict of law provisions. Our failure to enforce
              any right or provision of these Terms will not be considered a
              waiver of those rights. If any provision of these Terms is held to
              be invalid or unenforceable by a court, the remaining provisions
              of these Terms will remain in effect. These Terms constitute the
              entire agreement between us regarding our Service and supersede
              and replace any prior agreements we might have had between us
              regarding Service.
            </p>

            <h4>Changes To Service</h4>
            <p>
              We reserve the right to withdraw or amend our Service, and any
              service or material we provide via Service, in our sole discretion
              without notice. We will not be liable if for any reason all or any
              part of Service is unavailable at any time or for any period. From
              time to time, we may restrict access to some parts of Service, or
              the entire Service, to users, including registered users.
            </p>

            <h4>Amendments To Terms</h4>
            <p>
              We may amend Terms at any time by posting the amended terms on
              this site. It is your responsibility to review these Terms
              periodically. Your continued use of the Platform following the
              posting of revised Terms means that you accept and agree to the
              changes. You are expected to check this page frequently so you are
              aware of any changes, as they are binding on you. By continuing to
              access or use our Service after any revisions become effective,
              you agree to be bound by the revised terms. If you do not agree to
              the new terms, you are no longer authorized to use Service.
            </p>

            <h4>Waiver And Severability</h4>
            <p>
              No waiver by Company of any term or condition set forth in Terms
              shall be deemed a further or continuing waiver of such term or
              condition or a waiver of any other term or condition, and any
              failure of Company to assert a right or provision under Terms
              shall not constitute a waiver of such right or provision. If any
              provision of Terms is held by a court or other tribunal of
              competent jurisdiction to be invalid, illegal or unenforceable for
              any reason, such provision shall be eliminated or limited to the
              minimum extent such that the remaining provisions of Terms will
              continue in full force and effect.
            </p>

            <h4>Acknowledgement</h4>
            <p>
              BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
              THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
              THEM.
            </p>

            <h4>Contact Us</h4>
            <p>
              Please send your feedback, comments, requests for technical
              support by{" "}
              <Link data-target="contactModal" className=" modal-trigger">
                contacting us.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
