import React from "react";

const Faq = () => {
  return (
    <>
      <div className="header-style">
        <div className="container center" style={{ color: "white" }}>
          <h1>Your queries, Answered.</h1>
        </div>
      </div>
      <div className="row">
        <div className="col s10 offset-s1">
          <h3>
            FAQs
            <p
              style={{
                border: "4px solid #3DCCC7",
                margin: "0px 0px",
                width: "2em",
              }}
            ></p>
          </h3>
          <h3>What is Vehiclent ?</h3>
          <p>
            Vehiclent is a network of technology-based vehicle service(Car &
            Two-wheelers) centres, who aims to make vehicle servicing(Car &
            Two-wheelers) and repair more easy & affordable. With our highly
            skilled technicians, transparent pricing, the manufacturer
            recommended procedures and the promise of genuine spare parts.
          </p>

          <h3>Which brands do you serve?</h3>
          <p>
            We service all major brands like Hyundai, Maruti-Suzuki, Honda,
            Tata, Mahindra, Volkswagen, Hero motor cycles, Yamaha, TVS and many
            more.
          </p>

          <h3>Which Areas do you serve currently?</h3>
          <p>
            Currently, we are servicing at Delhi, Noida, Gurgaon and Ghaziabad.
          </p>

          <h3>What products do you use?</h3>
          <p>
            We use Genuine products strictly as per OEM(Original Equipment
            Manufacturer) guidelines for our manufactured parts. We go through a
            filtering process to select the brands that are the best in quality
            and suitable for your car and then we let you choose the best option
            as your needs.
          </p>

          <h3>Why should I choose Vehiclent?</h3>
          <p>
            Vehiclent offers the best vehicle services(car & two-wheelers) and
            solutions at fair and flexible prices.
          </p>

          <h3>
            What if I am not available to drop my vehicle(Car & Two-wheeler)?
          </h3>
          <p>
            No need to Worry! We provide free pick-up and drop-in facility.
          </p>

          <h3>What type of payment do you accept?</h3>
          <p>
            We accept all digital payment options. You can pay via debit/credit
            cards, net banking and digital wallets like Paytm, PhonePe,
            PayUmoney etc directly through our platform. You can also choose to
            pay via cash at the time of delivery.
          </p>

          <h3>When do I pay?</h3>
          <p>
            You can choose to pay anytime after your booking is confirmed and
            before the hand over of your vehicle(Car & Two-wheeler) post
            service.
          </p>

          <h3>Do I get a bill?</h3>
          <p>
            Yes, an invoice is sent to your registered mobile number and email
            address on every service. You can also download a copy of the
            invoice by logging in and heading to the My Account’s section on the
            Vehiclent Dashboard. We also maintain digitalized service records of
            your vehicle(Car & Two-wheeler) online for any further reference.
          </p>
        </div>
      </div>
    </>
  );
};
export default Faq;
